import axios from 'axios';
export default class LargeFileUploader {
    static init(){
        let uploaders = document.getElementsByClassName('large-file-uploader');
        if (uploaders.length > 0){
            [...uploaders].forEach(uploader => {
                let dndZone = uploader.querySelector('.dnd-zone');
                dndZone.addEventListener('dragover', function(e){
                    e.preventDefault();
                    if (! dndZone.classList.contains('dragover')){
                        dndZone.classList.add('dragover');
                    }
                });
                dndZone.addEventListener('dragleave', function(e){
                    e.preventDefault();
                    if (dndZone.classList.contains('dragover')) {
                        dndZone.classList.remove('dragover');
                    }
                });
                dndZone.addEventListener('drop', function(e){
                    console.log('drop');
                    e.preventDefault();
                    LargeFileUploader.upload(uploader, e.dataTransfer.files);
                });
                let form = uploader.querySelector('.file-upload-form');
                form.addEventListener('submit', function(e){
                    console.log('submit');
                    e.preventDefault();
                    LargeFileUploader.upload(uploader, e.originalEvent.dataTransfer.files);
                });
                let filesField = uploader.querySelector('.file-field');
                filesField.addEventListener('change', function(e){
                    console.log('change');
                    console.log(e);
                    LargeFileUploader.upload(uploader, e.target.files);
                });

                LargeFileUploader.getUploadedFiles(uploader);
            });
        }
    }

    static getUploadedFiles(uploader){
        let url = uploader.querySelector('.file-list-api-endpoint').getAttribute('action');
        axios
            .get(url)
            .then(response => {
                if (response.data.result !== 'OK'){
                    toastr.error('アップロードファイル一覧取得に失敗しました');
                    return;
                }
                LargeFileUploader.showUploadedFiles(uploader, response.data.files);
            })
            .catch(error => {
                toastr.error('アップロードファイル一覧取得または一覧表示に失敗しました');
            });
    }

    static async upload(uploader, files){
        uploader.querySelector('.dnd-zone').classList.remove('dragover');

        let fileSelectButton = uploader.querySelector('.file-select-button');
        fileSelectButton.setAttribute('disabled', true);

        let progressContainer = uploader.querySelector('.progress-container');
        let fileProgress = progressContainer.querySelector('.file-progress');
        let progressSpinner = uploader.querySelector('.progress-spinner');

        progressContainer.classList.remove('d-none');
        progressSpinner.classList.remove('d-none');

        let form = uploader.querySelector('.file-upload-form');

        if (! files){
            // File specified by button
            files = form.querySelector('.file-field').files;
        }

        if (files.length > 1){
            fileProgress.classList.remove('d-none');
            fileProgress.querySelector('.no').textContent = 1;
            fileProgress.querySelector('.total').textContent = files.length;
        } else {
            fileProgress.classList.add('d-none');
        }

        let status = {
            uploaded: 0,
            error: 0,
        };
        for (let i = 0; i < files.length; i++){
            if (files.length > 1){
                fileProgress.querySelector('.no').textContent = i + 1;
            }
            if (files[i].size > 1024 * 1024 * 400){
                toastr.error('400MB以上のファイルはアップロードできません');
                continue;
            }

            uploader.querySelector('.progress .progress-bar').remove();
            let progress = document.createElement('div');
            progress.classList.add('progress-bar');
            progress.classList.add('progress-bar-striped');
            progress.classList.add('progress-bar-animated');
            progress.setAttribute('style', 'width: 0%');
            uploader.querySelector('.progress').appendChild(progress);
            let uploadResult = await LargeFileUploader.uploadFile(uploader, files[i]);
            if (uploadResult){
                status.uploaded++;
            } else {
                status.error++;
            }
        }
        if (status.uploaded > 0){
            toastr.success(status.uploaded + '件のファイルをアップロードしました');
        }
        if (status.error > 0){
            toastr.error(status.error + '件のファイルをアップロードできませんでした');
        }
        fileSelectButton.setAttribute('disabled', false);
        progressContainer.classList.add('d-none');
        fileProgress.classList.add('d-none');
        progressSpinner.classList.add('d-none');

        LargeFileUploader.getUploadedFiles(uploader);
    }

    static async uploadFile(uploader, file){
        let chunkSize = 1024 * 1024 * 10;
        let start = 0;
        let sessionId;

        // start session
        let startSessionData = await LargeFileUploader.startSession(uploader);
        if (startSessionData.result !== 'OK'){
            return false;
        }
        console.log('session start: ' + startSessionData.sessionId);
        sessionId = startSessionData.sessionId;

        let progressContainer = uploader.querySelector('.progress-container');
        let progressBar = progressContainer.querySelector('.progress-bar');

        // upload chunk
        let chunkIndex = 0;
        while (start < file.size) {
            let chunk = file.slice(start, start + chunkSize);
            let data = await LargeFileUploader.uploadChunk(uploader, sessionId, chunkIndex, chunk);
            if (data.result !== 'OK'){
                return false;
            }
            console.log(data);
            start += chunkSize;
            progressBar.setAttribute('style', 'width: ' + Math.floor(start / file.size * 100) + '%');
            chunkIndex++;
        }

        // complete session
        let completeSessionData = await LargeFileUploader.completeSession(uploader, sessionId, chunkIndex, file);
        console.log('complete session:', completeSessionData);

        progressBar.setAttribute('style', 'width: 100%');

        // noinspection RedundantIfStatementJS
        if (completeSessionData.result !== 'OK'){
            return false;
        }

        return true;
    }

    static async calcHash(file){
        const arrayBuffer = await file.arrayBuffer();
        const hashBuffer = await crypto.subtle.digest('SHA-1', arrayBuffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
        return hashHex;
    }

    static async startSession(uploader){
        let formData = new FormData();
        formData.append('action', 'start');

        let form = uploader.querySelector('.file-upload-form');
        let url = form.getAttribute('action');

        try {
            const response = await fetch(url, {
                method: 'POST',
                body: formData
            });
            const data = await response.json();
            return data;
        } catch (error){
            return error;
        }
    }

    static async completeSession(uploader, sessionId, totalChunks, file){
        let fileSha1hash = await LargeFileUploader.calcHash(file);

        let formData = new FormData();
        formData.append('action', 'complete');
        formData.append('sessionId', sessionId);
        formData.append('totalChunks', totalChunks);
        formData.append('fileName', file.name);
        formData.append('fileType', file.type);
        formData.append('fileSize', file.size);
        formData.append('fileSha1', fileSha1hash);

        let form = uploader.querySelector('.file-upload-form');
        let url = form.getAttribute('action');

        try {
            const response = await fetch(url, {
                method: 'POST',
                body: formData
            });
            const data = await response.json();
            return data;
        } catch (error){
            return error;
        }
    }

    static async uploadChunk(uploader, sessionId, chunkIndex, chunk) {
        console.log('sessionId: ' + sessionId + '  chunkIndex: ' + chunkIndex);

        let formData = new FormData();
        formData.append('action', 'chunk');
        formData.append('sessionId', sessionId);
        formData.append('chunkIndex', chunkIndex);
        formData.append('chunk', chunk);

        let form = uploader.querySelector('.file-upload-form');
        let url = form.getAttribute('action');

        try {
            const response = await fetch(url, {
                method: 'POST',
                body: formData
            });
            const data = await response.json();
            return data;
        } catch (error){
            return error;
        }
    }

    static delete(button, deleteEndpoint){
        if (! confirm('削除します。よろしいですか？')){
            return;
        }

        let uuid = button.getAttribute('data-uuid');
        let uploader = button.closest('.large-file-uploader');
        axios
            .post(deleteEndpoint, {
                uuid: uuid,
            })
            .then(response => {
                if (response.data.result !== 'OK'){
                    toastr.error('削除できませんでした');
                    return;
                }
                let li = uploader.querySelector('li[data-uuid="' + response.data.uuid + '"]');
                if (li){
                    li.parentNode.removeChild(li);
                    toastr.success('削除しました');
                } else {
                    toastr.error('削除に失敗しました');
                }
            })
            .catch(error => {
                toastr.error('削除に失敗しました');
            });
    }

    static showUploadedFiles(uploader, files){
        let fileList = uploader.querySelector('.file-list');
        if (! fileList){
            console.log('Something wrong..');
            return;
        }
        let lis = fileList.querySelectorAll('li');
        [...lis].forEach(li => {
            li.parentNode.removeChild(li);
        });

        files.forEach(file => {
            let li = document.createElement('li');
            li.className = 'd-flex align-items-center mB-5';
            li.setAttribute('data-uuid', file.uuid);

            let deleteButton = document.createElement('div');
            deleteButton.className = 'btn btn-light-danger btn-sm btn-icon delete-button mR-10';
            deleteButton.setAttribute('data-uuid', file.uuid);
            let deleteIcon = document.createElement('i');
            deleteIcon.className = 'fa fa-trash';
            deleteButton.appendChild(deleteIcon);

            deleteButton.addEventListener('click', function(){
                LargeFileUploader.delete(this, file.api_endpoints.delete);
            });

            li.appendChild(deleteButton);

            let containerDiv = document.createElement('div');

            let aDiv = document.createElement('div');
            let a = document.createElement('a');
            a.href = file.api_endpoints.download;
            a.className = 'flex-grow-1 text-break';
            a.textContent = file.filename;
            aDiv.appendChild(a);
            containerDiv.appendChild(aDiv);

            if (file.filesizeKB !== undefined){
                let sizeDiv = document.createElement('div');
                sizeDiv.className = 'small text-muted';
                sizeDiv.textContent = file.filesizeKB + 'KB';

                containerDiv.appendChild(sizeDiv);
            }

            li.appendChild(containerDiv);

            fileList.appendChild(li);
        });
    }
}
